export const WhatsappButton = (props) => {
  return (
    <div>
      <a
        href={`https://api.whatsapp.com/send?phone=${
          props?.data?.phone ? props.data.phone : ""
        }`}
        class="float-wpp"
        target="_blank"
      >
        <i class="fa fa-whatsapp float-wpp-icon"></i>
      </a>
    </div>
  );
};
