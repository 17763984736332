import { useState } from "react";
import GoogleMapReact from "google-map-react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
  number: null,
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export const Contact = (props) => {
  const [{ name, email, message, number }, setState] = useState(initialState);
  const [alert, setAlert] = useState(<></>);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    setAlert(<></>);
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "amor_decoracoes_email",
        "amor_decoracoes_template",
        e.target,
        "9aoY7YQ6qp1gfGCc1"
      )
      .then(
        (result) => {
          setAlert(
            <div class="alert alert-success">
              <strong>Sucesso!</strong> A mensagem foi enviada.
            </div>
          );
          clearState();
        },
        (error) => {
          setAlert(
            <div class="alert alert-error">
              <strong>Erro!</strong>
              {error.text}
            </div>
          );
        }
      );
  };

  // const mapProps = {
  //   center: {
  //     lat: -23.496792406258965,
  //     lng: -46.84712160219501,
  //   },
  //   zoom: 15,
  // };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contato</h2>
                <p>
                  Por favor, preencha o formulário abaixo para entrar em
                  contato, retornaremos em breve!
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        className="form-control"
                        placeholder="Nome"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        id="number"
                        name="number"
                        value={number}
                        className="form-control"
                        placeholder="Número de Telefone"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    value={message}
                    className="form-control"
                    rows="4"
                    placeholder="Menssagem"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar
                </button>
              </form>
              {alert}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de Contato</h3>
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props?.data ? props.data.email : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <a
                style={{ color: "white" }}
                href={`https://api.whatsapp.com/send?phone=${
                  props?.data?.phone ? props.data.phone : ""
                }`}
              >
                <span>
                  <i className="fa fa-whatsapp"></i> Whatsapp
                </span>{" "}
                {props?.data ? props.data.phone : "loading"}
              </a>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
          </div>
          {/* <div
            className="col-md-3 col-md-offset-1 contact-info map-container"
            style={{ height: "400px", width: "100%" }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={mapProps.center}
              defaultZoom={mapProps.zoom}
              yesIWantToUseGoogleMapApiInternals
            >
              <AnyReactComponent
                lat={-23.496792406258965}
                lng={-46.84712160219501}
                text="M.A.R"
              />
            </GoogleMapReact>
          </div> */}

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2022 Forked by{" "}
            <a href="https://github.com/amorim33" rel="nofollow">
              AluisioDev
            </a>
            . Template by{" "}
            <a href="https://github.com/issaafalkattan" rel="nofollow">
              Issaafalkattan
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
